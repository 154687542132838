<script>


import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";


import { useSessionApi } from "@/apis/session";
import { Session } from "@/helpers/storage";
/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    email: {
      required
    },
    password: {
      required,
    },
  },
  computed: {
    
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
  
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      useSessionApi().login_in({account : this.email, password : this.password}).then(res =>{
       
        if (res.err == 0) {
          Session.set('token', res.data.token)
          Session.set('profile', res.data)
          this.$router.push({'name': 'home'})
        } else {
          this.authError   = res.msg
          this.isAuthError = true
        }
      })
    },
  }
};
</script>

<template>
  <div class="authentication-bg min-vh-100">
    <div class="bg-overlay bg-white"></div>
    <div class="container">
      <div class="d-flex flex-column min-vh-100 px-3 pt-4">
        <div class="row justify-content-center my-auto">
          <div class="col-md-8 col-lg-6 col-xl-4">
            <div class="text-center py-5">
              <div class="mb-4 mb-md-5">
                <router-link to="/" class="d-block auth-logo">
                  <img
                    src="@/assets/images/logo-dark.png"
                    alt=""
                    height="22"
                    class="auth-logo-dark"
                  />
                  <img
                    src="@/assets/images/logo-light.png"
                    alt=""
                    height="22"
                    class="auth-logo-light"
                  />
                </router-link>
              </div>
              <div class="mb-4">
                <h5>深蓝航天</h5>
                <p>生产库存管理平台</p>
              </div>
              <b-alert
                v-model="isAuthError"
                variant="danger"
                class="mt-3"
                dismissible
                >{{ authError }}</b-alert
              >
              <div
                v-if="notification.message"
                :class="'alert ' + notification.type"
              >
                {{ notification.message }}
              </div>
              <form @submit.prevent="tryToLogIn">
                <div class="form-floating form-floating-custom mb-3">
                  <input
                    type="text"
                    v-model="email"
                    class="form-control"
                    id="input-email"
                    placeholder="Enter User Name"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                  />
                  <label for="input-email">用户名</label>
                  <div class="form-floating-icon">
                    <i class="uil uil-envelope-alt"></i>
                  </div>
                  <div
                    v-if="submitted && $v.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.email.required">请输入用户名</span>
                   
                  </div>
                </div>
                <div class="form-floating form-floating-custom mb-3">
                  <input
                    type="password"
                    class="form-control"
                    v-model="password"
                    id="input-password"
                    placeholder="Enter Password"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                  />
                  <label for="input-password">密码</label>
                  <div class="form-floating-icon">
                    <i class="uil uil-padlock"></i>
                  </div>
                  <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                  >
                    请输入密码
                  </div>
                </div>

                <!-- <div class="form-check form-check-info font-size-16">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="remember-check"
                  />
                  <label
                    class="form-check-label font-size-14"
                    for="remember-check"
                  >
                    Remember me
                  </label>
                </div> -->

                <div class="mt-3">
                  <button class="btn btn-info w-100" type="submit">
                    登录
                  </button>
                </div>

                <!-- <div class="mt-4">
                  <router-link
                    to="/auth/resetpassword-basic"
                    class="text-muted text-decoration-underline"
                    >Forgot your password?</router-link
                  >
                </div> -->
              </form>
              <!-- end form -->

              <!-- <div class="mt-5 text-center text-muted">
                <p>
                  Don't have an account ?
                  <router-link
                    to="/register"
                    class="fw-medium text-decoration-underline"
                  >
                    Signup
                  </router-link>
                </p>
              </div> -->
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->

        <div class="row">
          <div class="col-xl-12">
            <div class="text-center text-muted p-4">
              <p class="mb-0">
                &copy; {{ new Date().getFullYear() }} 生产库存管理平台
                <i class="mdi mdi-heart text-danger"></i> by 青科
              </p>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
    <!-- end container -->
  </div>
  <!-- end authentication section -->
</template>

